// Note: Requires flexslider 2, buddy.
jQuery(document).ready(function($) {
	// $('.offers-three-slider-wrapper').flexslider({
	// 	animation: "slide",
	// 	prevText: "",
	// 	nextText: "",
	// 	controlNav: true,
	// 	directionNav: false,
	// 	// itemWidth: 561,
	// 	// itemMargin: 72,
	// 	// minItems: 1,
	// 	// maxItems: 3,
	// 	move: 3,
	// 	selector: ".slides > div",
	// });
	$('.offers-three-slider-wrapper:not(.no-slider)').slick({
		dots: true,
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 3,
		arrows: false,
		responsive: [
			{
				breakpoint: 1400,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				}
			},
			{
				breakpoint: 1000,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				}
			}
		]
	});
}); /* end of as page load scripts */